import React, { useState } from "react";
import "../scss/_all-task.scss";
import showToast from "./ToastUtils";
import { apiUrl } from "./apiConfig";
import axios from "axios";
import AddTask from "./AddTask";

const AllTask = ({ typeList, setTypeList, fetchTypeData }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [showAddTypePopup, setShowAddTypePopup] = useState(false);
  const handleEditClick = (index) => {
    setEditIndex(index);
  };

  /* Update task in database */
  const handleSaveClick = async (index) => {
    const updatedTask = typeList[index];
    const params = new URLSearchParams();
    params.append("identifier", "update-types");
    params.append("id", updatedTask.type_id);
    params.append("type_name", updatedTask.type_name);
    params.append("rate", updatedTask.rate);

    try {
      const url = `${apiUrl}?${params.toString()}`;
      const response = await axios.post(url, null, {
        withCredentials: true,
        headers: {},
      });

      if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "failed") {
          showToast("error", response.data.message);
        } else if (response.data.status === "success") {
          showToast("success", "Task updated");
        }
        fetchTypeData();
      } else {
        showToast("error", "Failed to update task");
        throw new Error("Failed to add project");
      }
    } catch (error) {
      showToast("error", "Failed to update task");
    }
    setEditIndex(null);
  };

  const handleAddTypeToDB = () => {
    setShowAddTypePopup(true);
  };
  return (
    <div className="all-task-outer-wrapper">
      {typeList.map((type, index) => (
        <div key={type.type_id} className="task-item">
          {editIndex === index ? (
            <>
              <div className="edit">
                <input
                  className={`each-task`}
                  value={type.type_name}
                  onChange={(e) => {
                    const updatedTypes = [...typeList];
                    updatedTypes[index] = {
                      ...typeList[index],
                      type_name: e.target.value,
                    };
                    setTypeList(updatedTypes);
                  }}
                />
              </div>

              <button onClick={() => handleSaveClick(index)}>
                <img src="./image/save.svg" alt="Save" />
              </button>
            </>
          ) : (
            <>
              <span>{type.type_name}</span>
              {editIndex !== null && editIndex === index && (
                <span>{type.rate}</span>
              )}
              <button onClick={() => handleEditClick(index)}>
                <img src="./image/edit.svg" alt="Edit" />
              </button>
            </>
          )}
        </div>
      ))}
      <button className="add-btn" onClick={handleAddTypeToDB}>
        <img src="./image/add-circle.svg" alt="Add Task" />
        Add Task
      </button>
      <div className={`add-client-wrapper ${showAddTypePopup ? "active" : ""}`}>
        {/* {showAddTypePopup && ( */}
        <AddTask
          showAddTypePopup={showAddTypePopup}
          setShowAddTypePopup={setShowAddTypePopup}
          fetchTypeData={fetchTypeData}
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default AllTask;
