// DateRangePicker.js
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../scss/_date-picker.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  format,
} from "date-fns";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DateRangePicker = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [rangeType, setRangeType] = useState("today");
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  useEffect(() => {
    onDateChange(
      format(startDate, "yyyy-MM-dd"),
      format(endDate, "yyyy-MM-dd")
    );
  }, [startDate, endDate, onDateChange]);

  const handleRangeChange = (e) => {
    const value = e.target.value;
    setRangeType(value);

    if (value === "week") {
      const start = startOfWeek(new Date());
      const end = endOfWeek(new Date());
      setStartDate(start);
      setEndDate(end);
    } else if (value === "month") {
      const now = new Date();
      const start = startOfMonth(now);
      const end = endOfMonth(now);
      setStartDate(start);
      setEndDate(end);
      setSelectedMonth(now.getMonth());
    } else if (value === "today") {
      const today = new Date();
      setStartDate(today);
      setEndDate(today);
    }
  };

  const handleMonthChange = (e) => {
    const monthIndex = parseInt(e.target.value, 10);
    const now = new Date();
    const selectedDate = new Date(now.getFullYear(), monthIndex, 1);
    setStartDate(startOfMonth(selectedDate));
    setEndDate(endOfMonth(selectedDate));
    setSelectedMonth(monthIndex);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    if (date >= startDate) {
      setEndDate(date);
    } else {
      toast.error("End date cannot be earlier than start date!");
    }
  };

  return (
    <div className="date-picker-wrap">
      <ToastContainer />
      <div className="view-report">
        <label>View Report:</label>
        <select value={rangeType} onChange={handleRangeChange}>
          <option value="today">Today</option>
          <option value="week">Weekly</option>
          <option value="month">Monthly</option>
        </select>
      </div>
      {rangeType === "month" && (
        <div className="month">
          <label>Select Month:</label>
          <select value={selectedMonth} onChange={handleMonthChange}>
            {months.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="start-date">
        <label>Start Date:</label>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div className="end-date">
        <label>End Date:</label>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          dateFormat="yyyy-MM-dd"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
