// apiConfig.js
// export const apiUrl =
//   // "https://telemetry.matsio.com/matsio/utility-tracker/api/";
// "https://dev.matsio.com/matsio/utility-tracker/api/";

/* Live site url */
export const apiUrl = "https://telemetry.matsio.com/api/";

/* For local host  */
// export const apiUrl = "https://dev.matsio.com/matsio/utility-tracker/api/";
