import React, { useState } from "react";
import "../scss/_all-client.scss";
import AddProjectToDB from "./AddProjectToDB";
import AddClient from "./AddClient";
import showToast from "./ToastUtils";
import { apiUrl } from "./apiConfig";
import axios from "axios";

const AllClient = ({ clientList, setClientList, fetchClientData }) => {
  /* States */
  const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);
  const [showAddClientPopup, setShowAddClientPopup] = useState(false);
  const [expandedClients, setExpandedClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [editedProjectIndex, setEditedProjectIndex] = useState(null);
  const [clientEditIndex, setClientEditIndex] = useState(null);
  const [projectNames, setProjectNames] = useState({});

  let clients = [];
  /* Extract client names from the projectList object */
  if (clientList && Object.keys(clientList).length > 0) {
    clients = Object.values(clientList).map((client) => ({
      clientId: client.client_id,
      clientName: client.client_name,
      projects: client.project.map((project) => ({
        projectId: project.project_id,
        projectName: project.project_name,
        edited: editedProjectIndex === project.project_id,
      })),
      expanded: expandedClients.includes(client.client_id),
    }));
  }

  const handleAddProjectToDB = (clientID, clientName) => {
    setSelectedClient({ clientId: clientID, clientName: clientName });
    setShowAddProjectPopup(true);
  };

  const handleEditProject = (projectId) => {
    setEditedProjectIndex(projectId);
  };

  const handleSaveProject = async (projectId, projectName, clientID) => {
    // Create URLSearchParams object to hold parameters
    const params = new URLSearchParams();
    params.append("identifier", "update-project");
    params.append("client_id", clientID);
    params.append("project_id", projectId);
    params.append("project_name", projectName);
    try {
      const url = `${apiUrl}?${params.toString()}`;
      const response = await axios.post(url, null, {
        withCredentials: true,
        headers: {},
      });

      if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "failed") {
          showToast("error", response.data.message);
        } else if (response.data.status === "success") {
          showToast("success", "Project updated");
        }
        fetchClientData();
      } else {
        showToast("error", "Failed to update project");
        throw new Error("Failed to update project");
      }
    } catch (error) {
      showToast("error", "Failed to update project");
    }
    setClientEditIndex(null);
    setEditedProjectIndex(null);
  };

  const handleClientClick = (clientId) => {
    setExpandedClients((prevExpanded) => {
      if (prevExpanded.includes(clientId)) {
        return [];
      } else {
        return [clientId];
      }
    });
  };

  const handleEditClient = (index) => {
    setClientEditIndex(index);
  };

  const handleAddProjectToDBWrapper = (client) => {
    return () => handleAddProjectToDB(client.clientId, client.clientName);
  };

  /* Update client in database */
  const handleUpdateClient = async (clientId, clientName) => {
    const params = new URLSearchParams();
    params.append("identifier", "update-clients");
    params.append("id", clientId);
    params.append("client_name", clientName);

    try {
      const url = `${apiUrl}?${params.toString()}`;
      const response = await axios.post(url, null, {
        withCredentials: true,
        headers: {},
      });

      if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "failed") {
          showToast("error", response.data.message);
        } else if (response.data.status === "success") {
          showToast("success", "Client updated");
        }
        fetchClientData();
      } else {
        showToast("error", "Failed to update Client");
        throw new Error("Failed to update client");
      }
    } catch (error) {
      showToast("error", "Failed to update client");
    }
    setClientEditIndex(null);
  };

  return (
    <div className="all-client-outer-wrapper">
      {clients.map((client, index) => (
        <div key={client.clientId} className="client-item">
          {clientEditIndex === client.clientId ? (
            <div className="edit">
              <input
                type="text"
                className={`client-header`}
                value={client.clientName}
                onChange={(e) => {
                  const updatedClients = { ...clientList };
                  updatedClients[client.clientId] = {
                    ...clientList[client.clientId],
                    client_name: e.target.value,
                  };
                  setClientList(updatedClients);
                }}
              />
              <button
                onClick={() =>
                  handleUpdateClient(client.clientId, client.clientName)
                }
                className="save-btn"
              >
                <img src="./image/save.svg" alt="Save" />
              </button>
            </div>
          ) : (
            <div className="each-row">
              <div className="default-view">
                <button
                  className={`client-header ${
                    client.expanded ? "expanded" : ""
                  }`}
                  onClick={() => handleClientClick(client.clientId)}
                >
                  {client.clientName}
                  <img src="./image/up-arrow.svg" alt="Dropdown" />
                </button>
                <button
                  className="edit-btn"
                  onClick={() => handleEditClient(client.clientId)}
                >
                  <img src="./image/edit.svg" alt="Dropdown" />
                </button>
              </div>
              <div
                className={`project-list ${client.expanded ? "expanded" : ""}`}
              >
                {client.projects.map((project) => (
                  <div key={project.projectId} className="project-item">
                    {project.edited ? (
                      <>
                        <input
                          className={`each-project`}
                          value={
                            projectNames[project.projectId] ??
                            project.projectName
                          }
                          onChange={(e) => {
                            setProjectNames((prevProjectNames) => ({
                              ...prevProjectNames,
                              [project.projectId]: e.target.value,
                            }));
                          }}
                        />
                        <button
                          className="save-btn"
                          onClick={() =>
                            handleSaveProject(
                              project.projectId,
                              projectNames[project.projectId] ||
                                project.projectName,
                              client.clientId
                            )
                          }
                        >
                          <img src="./image/save.svg" alt="Save" />
                        </button>
                      </>
                    ) : (
                      <>
                        <span>{project.projectName}</span>
                        <button
                          className="edit-btn"
                          onClick={() => handleEditProject(project.projectId)}
                        >
                          <img src="./image/edit.svg" alt="Edit" />
                        </button>
                      </>
                    )}
                  </div>
                ))}
                <button
                  className="add-btn"
                  onClick={handleAddProjectToDBWrapper(client)}
                >
                  <img src="./image/add-circle.svg" alt="Add project" />
                  Add Project
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
      <button className="add-btn" onClick={() => setShowAddClientPopup(true)}>
        <img src="./image/add-circle.svg" alt="Add project" />
        Add Client
      </button>
      <div
        className={`add-client-wrapper ${showAddClientPopup ? "active" : ""}`}
      >
        {/* {showAddClientPopup && ( */}
        <AddClient
          showAddClientPopup={showAddClientPopup}
          setShowAddClientPopup={setShowAddClientPopup}
          fetchClientData={fetchClientData}
        />
        {/* )} */}
      </div>
      <div
        className={`add-project-wrapper ${
          showAddProjectPopup && selectedClient ? "active" : ""
        }`}
      >
        {/* {selectedClient && showAddProjectPopup && ( */}
        <AddProjectToDB
          showAddProjectPopup={showAddProjectPopup}
          setShowAddProjectPopup={setShowAddProjectPopup}
          selectedClient={selectedClient}
          fetchClientData={fetchClientData}
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default AllClient;
