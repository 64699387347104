import { apiUrl } from "./apiConfig";
import "../scss/_admin-page.scss";
import "../scss/_add-project.scss";
import showToast from "./ToastUtils";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
const AddProjectToDB = ({
  showAddProjectPopup,
  setShowAddProjectPopup,
  selectedClient,
  fetchClientData,
}) => {
  const popupRef = useRef(null);

  // const togglePopup = (e) => {
  //   setShowAddProjectPopup(false);
  // };
  const [typeList, setTypeList] = useState([]);
  const [projectName, setProjectName] = useState("");

  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    const fetchTypeData = async () => {
      try {
        const response = await axios.get(`${apiUrl}?identifier=get-types`, {
          withCredentials: true,
        });
        setTypeList(response.data.data);
      } catch (error) {
        console.error("Error fetching types:", error);
        return [];
      }
    };

    /* Fetch type names and IDs */
    fetchTypeData();

    /* Handle outside click to close the popup */
    const handleOutsideClick = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setShowAddProjectPopup(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleAddProjectDB = () => {
    handleSaveProject(projectName);
  };

  const handleSaveProject = async (typeName) => {
    const params = new URLSearchParams();
    params.append("identifier", "add-project");
    params.append("clientId", selectedClient.clientId);
    params.append("typeId", selectedType);
    params.append("projectName", projectName);

    try {
      const url = `${apiUrl}?${params.toString()}`;
      const response = await axios.post(url, null, {
        withCredentials: true,
        headers: {},
      });

      if (response.status >= 200 && response.status < 300) {
        showToast("success", "Project added successfully");
        fetchClientData();
      } else {
        showToast("error", "Failed to add project");
        throw new Error("Failed to add project");
      }
    } catch (error) {
      showToast("error", "Failed to add project");
    }
  };

  const handleChangeProjectDetails = (e) => {
    setProjectName(e.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const togglePopup = (e) => {
    setShowAddProjectPopup(false);
  };
  return (
    <>
      <div className={`overlay   ${showAddProjectPopup ? "active" : ""}`}>
        <div className="close-btn-wrapper">
          <button className="popup-close" onClick={togglePopup}>
            <img src="./image/close-small.svg" alt="Close" />
          </button>
        </div>
        <div className="content" ref={popupRef}>
          <h2 className="title">Add Project</h2>
          <div className="add-project-db">
            <label htmlFor="client-select">Client</label>
            <input
              type="text"
              value={selectedClient ? selectedClient.clientName : null}
            />
            <label htmlFor="type-select">Type</label>
            <select
              id="type-select"
              value={selectedType}
              onChange={handleTypeChange}
            >
              <option value="">Select Type</option>
              {typeList.map((type) => (
                <option key={type.type_id} value={type.type_id}>
                  {type.type_name}
                </option>
              ))}
            </select>

            <label htmlFor="project-name">Project Name</label>
            <input
              id="project-name"
              type="text"
              value={projectName}
              onChange={handleChangeProjectDetails}
              placeholder="Enter project name"
            />
            <button onClick={handleAddProjectDB}>Add project</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProjectToDB;
