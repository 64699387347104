// AdminPage.jsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "./apiConfig";
import "../scss/_admin-page.scss";
import AllClient from "./AllClient";
import AllTask from "./AllTask";
import Reports from "./Reports";

const AdminPage = () => {
  /* States */
  const [activeTab, setActiveTab] = useState("client");
  const [clientList, setClientList] = useState();
  const [typeList, setTypeList] = useState([]);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchClientData();
    fetchTypeData();
  }, []);

  /* Fetch client data from DB */
  const fetchClientData = async () => {
    try {
      const response = await axios.get(`${apiUrl}?identifier=get-project`, {
        withCredentials: true,
      });
      const projectData = response.data.data;
      setClientList(projectData.client);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  /* Fetch task details from DB */
  const fetchTypeData = async () => {
    try {
      const response = await axios.get(`${apiUrl}?identifier=get-types`, {
        withCredentials: true,
      });
      setTypeList(response.data.data);
    } catch (error) {
      console.error("Error fetching types:", error);
      return [];
    }
  };

  return (
    <div className="admin-page">
      <div className="top-sec">
        <div className="wrap">
          <h2 className="h2">Admin View</h2>
        </div>
      </div>
      <div className="tab-section">
        <div className="wrap">
          <a
            className={`tab ${activeTab === "client" ? "active" : ""}`}
            onClick={() => handleTabClick("client")}
          >
            Client
          </a>
          <a
            className={`tab ${activeTab === "type" ? "active" : ""}`}
            onClick={() => handleTabClick("type")}
          >
            Type
          </a>
          <a
            className={`tab ${activeTab === "reports" ? "active" : ""}`}
            onClick={() => handleTabClick("reports")}
          >
            Reports
          </a>
        </div>
      </div>
      <div className="content-section">
        {activeTab === "client" && (
          <AllClient
            clientList={clientList}
            setClientList={setClientList}
            fetchClientData={fetchClientData}
          />
        )}
        {activeTab === "type" && (
          <AllTask
            typeList={typeList}
            setTypeList={setTypeList}
            fetchTypeData={fetchTypeData}
          />
        )}
        {activeTab === "reports" && <Reports />}
      </div>
    </div>
  );
};

export default AdminPage;
