import React, { useState, useRef, useEffect } from "react";
import "../scss/_add-project.scss";
import CustomPopupUtils from "./CustomPopupUtils";
const AddProject = ({ addProject, projectList, addLog, workType, logList }) => {
  const { showPopup, renderPopup } = CustomPopupUtils();
  const [isCustomPopupVisible, setCustomPopupVisible] = useState(false);
  const [userInput, setUserInput] = useState(0);
  const [rightpopup, setRightPopup] = useState(false);

  const [selectedClient, setSelectedClient] = useState({
    clientId: null,
    clientName: null,
  });
  const [selectedProject, setSelectedProject] = useState({
    projectId: null,
    projectName: null,
  });
  const [selectedTask, setSelectedTask] = useState({
    taskId: null,
    taskName: null,
  });
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const [selectedClientProjects, setSelectedClientProjects] = useState([]);
  const popupRef = useRef(null);

  /* Handle outside click to close the popup */
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setRightPopup(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  /* Extract client names from the projectList object */
  const client = Object.values(projectList).map((client) => ({
    clientId: client.client_id,
    clientName: client.client_name,
  }));

  /*  Extract Task name from wrok type */
  const task = workType.map((type) => ({
    taskId: type.type_id,
    taskName: type.type_name,
  }));

  /* handler function to toggle custom popup */
  const toggleCustomPopup = (message, type) => {
    showPopup(
      message,
      type,
      () => {
        setCustomPopupVisible(false);
      },
      () => {
        setCustomPopupVisible(false);
      }
    );
    setCustomPopupVisible(true);
  };

  /*  Handler function to update selected client ID and populate projects */
  const handleClientSelectChange = (event) => {
    const selectedClientId = event.target.value;
    const selectedClientData = projectList[selectedClientId];

    setSelectedClient({
      clientId: selectedClientData.client_id,
      clientName: selectedClientData.client_name,
    });

    /* Extract projects for the selected client */
    const projects = selectedClientData.project.map((project) => ({
      projectId: project.project_id,
      projectName: project.project_name,
    }));

    /* Set projects for the selected client */
    setSelectedClientProjects(projects);
    setSelectedProject({ projectId: null, projectName: null }); // Reset selected project
  };

  /* Handler function to update selected project ID */

  const handleProjectSelectChange = (event) => {
    const selectedProjectId = event.target.value;
    const selectedProjectName =
      event.target.options[event.target.selectedIndex].text;
    setSelectedProject({
      projectId: selectedProjectId,
      projectName: selectedProjectName,
    });
  };

  const handleTaskSelectChange = (event) => {
    const selectedTaskId = event.target.value;
    const selectedTaskName =
      event.target.options[event.target.selectedIndex].text;
    setSelectedTask({
      taskId: selectedTaskId,
      taskName: selectedTaskName,
    });
  };

  const togglePopup = (e) => {
    setRightPopup(!rightpopup);
  };

  const closePopup = (e) => {
    setRightPopup(false);
  };
  const projectAddDate = new Date();

  const handleClose = () => {
    setSelectedClient({ clientId: null, clientName: null });
    setSelectedProject({ projectId: null, projectName: null });
    setSelectedTask({ taskId: null, taskName: null });
    setSelectedTypeId("");
    setUserInput("");
    togglePopup();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !selectedProject.projectName ||
      !selectedClient.clientName ||
      !selectedTask.taskName
    ) {
      toggleCustomPopup(
        "Please select a value for Client, Project, and Type of work.",
        "alert"
      );
      return;
    }

    const date = projectAddDate.toISOString();

    /* Call addLog function with additional information */
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;

    const formData = {
      project_id: selectedProject.projectId,
      client_id: selectedClient.clientId,
      client_name: selectedClient.clientName,
      date: new Date().toISOString(),
      log_id: formattedDate,
      project_name: selectedProject.projectName,
      rate:
        workType.find((type) => type.type_id === selectedTypeId)?.type_rate ||
        0 /*Default to 0 if not found */,
      type_id: selectedTask.taskId,
      type_name: selectedTask.taskName,
    };

    const isDuplicate = logList.some(
      (log) =>
        log.client_id == formData.client_id &&
        log.project_id == formData.project_id &&
        log.type_id == formData.type_id
    );

    if (isDuplicate) {
      toggleCustomPopup(
        "This Client, Project, and Task combination already exists in the View, please add there.",
        "alert"
      );
    } else {
      /*Call the addLog function with the form data*/
      addLog(formData);
      setSelectedClient({ clientId: null, clientName: null });
      setSelectedProject({ projectId: null, projectName: null });
      setSelectedTask({ taskId: null, taskName: null });
      setSelectedTypeId("");
      setCustomPopupVisible(false);
      setUserInput("");

      setRightPopup(false);
      togglePopup();
    }
    setRightPopup(false);
  };

  return (
    <>
      <button onClick={handleClose} className="add-project-btn">
        <img src="./image/add-circle.svg" alt="Add project" />
        Add Project
      </button>
      <div className={`overlay ${rightpopup ? "active" : ""}`}>
        <div className="close-btn-wrapper">
          <button className="popup-close" onClick={closePopup}>
            <img src="./image/close-small.svg" alt="Add project" />
          </button>
        </div>

        <div className="content" ref={popupRef}>
          <h2 className="title">Add Project</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="clientSelect">Client</label>
            <select
              value={selectedClient.clientId || ""}
              onChange={handleClientSelectChange}
            >
              <option value="" disabled selected>
                Select a Client
              </option>
              {client.map((client, index) => (
                <option key={index} value={client.clientId || ""}>
                  {client.clientName}
                </option>
              ))}
            </select>

            <label htmlFor="projectSelect">Project</label>

            <select
              id="projectSelect"
              value={selectedProject.projectId || ""}
              onChange={handleProjectSelectChange}
            >
              <option value="" disabled selected>
                Select a project
              </option>
              {selectedClientProjects.map((project, index) => (
                <option key={index} value={project.projectId}>
                  {" "}
                  {project.projectName}
                </option>
              ))}
            </select>

            <label htmlFor="typeSelect">Type of Work</label>
            <select
              id="typeSelect"
              value={selectedTask.taskId || ""}
              onChange={handleTaskSelectChange}
            >
              <option value="" disabled selected>
                Select a type
              </option>
              {task.map((task, index) => (
                <option key={index} value={task.taskId}>
                  {task.taskName}
                </option>
              ))}
            </select>
            <button onClick={handleSubmit} type="submit">
              Add Project
            </button>
          </form>
        </div>
      </div>
      {isCustomPopupVisible && renderPopup()}
    </>
  );
};

export default AddProject;
