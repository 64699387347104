import React from 'react';
import DateComponent from './DateComponent';
import '../../scss/pipeline_styles/_task.scss';

const Task = ({ id, title, profileImage, name, date, priority, status, myBoard, deadline, assignedProfileImage, assignedName }) => {
  const currentDate = new Date();
  const deadlineDate = new Date(deadline);

  const timeDifferenceInMilliseconds = deadlineDate - currentDate;
  const daysDifference = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));

  const getFlagColor = () => {
    if (status === 'requested') {
      return '#00C97A';
    } else {
      if (daysDifference < 1) {
        return '#FF828D';
      } else if (daysDifference < 3) {
        return '#FFAC4B';
      } else {
        return '#00C97A';
      }
    }
  };

  return (
    <div className={status === 'assigned' && !myBoard ? 'task assigned' : 'task'}>
      <h3 className="task-title">{title}</h3>
      <div className="task-footer">
        <div className="task-info">
          <img src={assignedProfileImage} alt={`${assignedName}'s profile`} className="profile-image" />
          <p className="user-name">{assignedName}</p>
        </div>
        <div className="task-date-wrap">
          <span className="task-date">{status === 'requested' ? <p className="requested">Requested</p> : <DateComponent date={deadline} />}</span>
          <span className="task-flag">
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
              <g clipPath="url(#clip0_1592_2555)">
                <path
                  d="M1.33337 5.00002C1.33337 5.00002 1.66671 4.66669 2.66671 4.66669C3.66671 4.66669 4.33337 5.33335 5.33337 5.33335C6.33337 5.33335 6.66671 5.00002 6.66671 5.00002V1.00002C6.66671 1.00002 6.33337 1.33335 5.33337 1.33335C4.33337 1.33335 3.66671 0.666687 2.66671 0.666687C1.66671 0.666687 1.33337 1.00002 1.33337 1.00002V5.00002Z"
                  fill={getFlagColor()}
                  stroke={getFlagColor()}
                  strokeWidth="0.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M1.33337 7.33333V5" stroke={getFlagColor()} strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_1592_2555">
                  <rect width="8" height="8" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Task;
