import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Task from './Task';
import moment from 'moment';
import TaskDetails from './TaskDetails';
import { apiUrl } from './../apiConfig';
import '../../scss/pipeline_styles/_pipeline-home.scss';

const PipelineHomePage = ({ userData, setUserData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tasks, setTasks] = useState([]);
  const [myTasks, setMyTasks] = useState([]);
  const [showAll, setShowAll] = useState('all');
  const [todayTaskCount, setTodayTaskCount] = useState(0);
  const [showFilterOptions, setshowFilterOptions] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const handleAddTaskClick = () => {
    setShowTask(true);
    setSelectedTask(false);
  };

  // const handlefilterButtonClick = () => {
  //   setshowFilterOptions(!showFilterOptions);
  // };

  const handleSearchButtonClick = (event) => {
    event.preventDefault();
    setSearchTerm('');
    setIsSearchExpanded(!isSearchExpanded);
  };

  const filterOptions = ['Content', 'Development', 'Design', 'Accounts'];

  const [selectedTask, setSelectedTask] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const handleTaskClick = (task) => {
    setShowTask(true);
    setSelectedTask(task);
  };

  const onDoubleClickTaskHandler = (task) => {
    if (task.status == 'active') {
      axios
        .get(`${apiUrl}?action=pipeline&identifier=self-assign&taskId=${task.id}`, {
          withCredentials: true
        })
        .then((res) => {
          if (res.data.status == 'success') {
            getTaskList(res.data.message);
          }
        });
    }
  };

  const onDoubleClickReturnTaskHandler = (task) => {
    axios
      .get(`${apiUrl}?action=pipeline&identifier=return-assign&taskId=${task.id}`, {
        withCredentials: true
      })
      .then((res) => {
        if (res.data.status == 'success') {
          console.log(res.data.message);
          getTaskList(res.data.message);
        }
      });
  };

  const today = moment().format('YYYY-MM-DD');
  const filteredTasks = tasks.filter((task) => {
    const taskDate = moment(task.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');

    const titleMatch = task.title.toLowerCase().includes(searchTerm.toLowerCase());
    const nameMatch = task.name.toLowerCase().includes(searchTerm.toLowerCase());
    const assignedNameMatch = task.assignedName.toLowerCase().includes(searchTerm.toLowerCase());
    const clientSlugMatch = task.clientSlug.toLowerCase().includes(searchTerm.toLowerCase());
    const dateMatch = showAll === 'today' ? taskDate === today : true;

    return (titleMatch || nameMatch || assignedNameMatch || clientSlugMatch) && dateMatch;
  });

  const handleTabChange = (button) => {
    setShowAll(button);
  };

  const getTaskList = (id) => {
    axios
      .get(`${apiUrl}?action=pipeline&identifier=get-pipeline-task`, {
        withCredentials: true
      })
      .then((res) => {
        if (res.data.status == 'success') {
          const json = res.data.data;
          const arr = [];
          var task = false;
          Object.keys(json).forEach(function (key) {
            arr.push(json[key]);
            if (id) {
              task = json[key];
            }
          });
          setTasks(arr);
          if (id) {
            setShowTask(true);
            setSelectedTask(json[id]);
          }

          /* Get count for today to show in top of the page */
          const today = new Date().toISOString().split('T')[0];
          const filteredData = Object.values(json).filter((obj) => obj.date.startsWith(today));
          const totalCount = filteredData.length;
          setTodayTaskCount(totalCount);

          /* Sort array to get my assigned list */
          const userId = userData.id;
          const myList = arr.filter((item) => item.assignedId === userId && item.status === 'assigned');
          setMyTasks(myList);
        }
      });
  };

  const updateOnsubmit = (id) => {
    if (id) {
      getTaskList(id);
    } else {
      getTaskList(false);
      setShowTask(false);
    }
  };

  /* Call API */
  useEffect(() => {
    getTaskList(false);
  }, []);

  return (
    <div className="pipeline-page">
      <div className="wrap">
        <div className="all-board">
          <p className="board-title">Matsio Board</p>
          <div className="all-board-container">
            <div className="top-wrap">
              <div className="top-bar">
                {/*
                <div className="filter-btn-container">
                  <button className={`filter-button ${showFilterOptions ? 'active' : ''}`} onClick={handlefilterButtonClick}>
                    <img src="./image/nav-btn.svg" alt="filter" />
                  </button>
                  {showFilterOptions && (
                    <ul className="filter-options-list">
                      {filterOptions.map((option, index) => (
                        <li key={index} className="filter-option-item">
                          {option}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                */}
                <button className={`task-count total-count ${showAll === 'all' ? 'active' : ''}`} onClick={() => handleTabChange('all')}>
                  <span className="label">All</span>
                  <span className="number">{tasks.length}</span>
                </button>

                <button className={`task-count today-count ${showAll === 'today' ? 'active' : ''}`} onClick={() => handleTabChange('today')}>
                  <span className="label">Today</span>
                  <span className="number">{todayTaskCount}</span>
                </button>

                <form onSubmit={handleSearchButtonClick} className={`search-form ${isSearchExpanded ? 'active' : ''}`}>
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search boards (#client, @tags)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button className="search">
                    <img src="./image/search.svg" alt="search" />
                  </button>
                </form>
              </div>
              <button className="add-task icon-btn" onClick={handleAddTaskClick}>
                <img src="./image/add-circle.svg" alt="add-task" />
                <span> Add a Task</span>
              </button>
            </div>
            <div className="task-container">
              {filteredTasks.map((task, index) => (
                <div key={task.id} onClick={() => handleTaskClick(task)} onDoubleClick={() => onDoubleClickTaskHandler(task)}>
                  <Task id={index} {...task} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="all-board my-board">
          <p className="board-title">My Board</p>
          <div className="all-board-container">
            <div className="task-container">
              {myTasks.map((task) => (
                <div key={task.id} onClick={() => handleTaskClick(task)} onDoubleClick={() => onDoubleClickReturnTaskHandler(task)}>
                  <Task {...task} myBoard={true} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {showTask && (
          <div className="task-expand-wrap">
            <TaskDetails task={selectedTask} userData={userData} updateOnsubmit={updateOnsubmit} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PipelineHomePage;
