import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "./apiConfig";
import "../scss/_project-wise-report.scss";
import DateRangePicker from "./DateRangePicker";
import { format } from "date-fns";

const ProjectReport = ({ weeks, setWeeks, generateWeekDates }) => {
  /* States */
  const today = new Date();
  const formattedToday = format(today, "yyyy-MM-dd");

  const [allClientsReportList, setAllClientsReportList] = useState([]);
  const [clientNames, setClientNames] = useState([]);
  const [selectedClient, setSelectedClient] = useState("all");
  const [startDate, setStartDate] = useState(formattedToday);
  const [endDate, setEndDate] = useState(formattedToday);
  const [selectedStartDate, setSelectedStartDate] = useState(formattedToday);
  const [selectedEndDate, setSelectedEndDate] = useState(formattedToday);
  const [detailsVisible, setDetailsVisible] = useState(false); // Single toggle for all clients

  /* API call for all client and project details */
  const fetchAllClientReport = async (startDateString, endDateString) => {
    try {
      const response = await axios.get(
        `${apiUrl}?identifier=report-projects&start=${startDateString}&end=${endDateString}`,
        { withCredentials: true }
      );

      const allClientReport = response.data.data;
      const allClientReportArray = Object.values(allClientReport);
      setAllClientsReportList(allClientReportArray);

      // Extract client names
      const clientNamesList = allClientReportArray.map(
        (client) => client.client_name
      );
      setClientNames(clientNamesList);
    } catch (error) {
      console.error("Error fetching All Project Report:", error);
    }
  };

  /* Fetch client data on initial mount */
  useEffect(() => {
    fetchAllClientReport(startDate, endDate);
  }, []); // Only run once on initial mount

  /* Client dropdown change function */
  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };

  /* Convert minutes to hours */
  const renderTotalTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    if (hours === 0) {
      return `${mins} mins`;
    } else if (mins === 0) {
      return `${hours} hours`;
    } else {
      return `${hours} hours ${mins} mins`;
    }
  };

  /* Handle Go button click */
  const handleGoClick = () => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    fetchAllClientReport(selectedStartDate, selectedEndDate);
  };

  /* Toggle details visibility for all clients */
  const toggleDetailsVisibility = () => {
    setDetailsVisible(!detailsVisible);
  };

  const renderClientDetails = (client) => (
    <div key={client.client_name} className="client-details-wrap">
      <div className="client-details">
        <h3 className="client-name">{client.client_name}</h3>
        <p className="total-time-wrap">
          <span className="total-time">
            {renderTotalTime(
              Object.values(client).reduce((acc, project) => {
                // Parse project.time to ensure it's a number
                const projectTime = parseInt(project.time, 10) || 0;
                return acc + projectTime;
              }, 0)
            )}
          </span>
        </p>
      </div>
      {(selectedClient !== "all" || detailsVisible) && (
        <ul className="project-list">
          {Object.values(client)
            .filter((project) => project.project_name)
            .map((project, index) => (
              <li key={index}>
                <span className="project-name">{project.project_name}</span>
                <span className="time">
                  {renderTotalTime(parseInt(project.time, 10) || 0)}
                </span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );

  return (
    <div className="project-report-container">
      <div className="date-picker-sec">
        <DateRangePicker
          onDateChange={(start, end) => {
            setSelectedStartDate(start);
            setSelectedEndDate(end);
          }}
        />
        <button className="go-btn" onClick={handleGoClick}>
          Go
        </button>
      </div>
      <div className="project-details-wrapper">
        <select onChange={handleClientChange} value={selectedClient}>
          <option value="all">All</option>
          {clientNames.map((name, index) => (
            <option key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
        {selectedClient === "all" && (
          <button onClick={toggleDetailsVisibility} className="details-btn">
            {detailsVisible ? "Hide Details" : "Show Details"}
          </button>
        )}
        {selectedClient === "all"
          ? allClientsReportList.map((client) => renderClientDetails(client))
          : allClientsReportList
              .filter((client) => client.client_name === selectedClient)
              .map((client) => renderClientDetails(client))}
      </div>
    </div>
  );
};

export default ProjectReport;
