import React, { useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import "../scss/_login.scss";
import { apiUrl } from "./apiConfig";

const Login = ({ setUserData, setIsAdmin }) => {
  const handleLoginSuccess = (response) => {
    /* Check if Google sent a JWT */
    if (response.credential) {
      /*  Decode the JWT to check if it is valid */
      const res = jwtDecode(response.credential);
      if (res.name && res.email) {
        /* Properly logged in */
        const profileData = {
          name: res.name,
          imageUrl: res.picture,
          email: res.email,
        };
        /* Send login details to API */
        const url = `${apiUrl}?identifier=verifyLogin&name=${profileData.name}&imageUrl=${profileData.imageUrl}&email=${profileData.email}`;
        performAPICall(url, setUserData, setIsAdmin);
      }
    }
  };

  const handleLoginFailure = (error) => {
    console.error("Login Failed:", error);
  };

  return (
    <div className="login-wrapper ">
      <div className="login-header">
        <div className="wrap">
          <a href="#/home">
            <img src="./image/Logo.svg" alt="Matsio" />
          </a>
          <h2>Matsio Telemetry</h2>
        </div>
      </div>
      <div className="login-outer-container">
        <div className="login-container">
          <h2>Login or signup</h2>
          <p>Kindly access your account using your email associated with @matsio.com. This login is intended exclusively for internal use.</p>
          <GoogleLogin buttonText="Login with Google" onSuccess={handleLoginSuccess} onFailure={handleLoginFailure} cookiePolicy="single_host_origin" scope="profile" ux_mode="prompt" />
        </div>
      </div>
    </div>
  );
};
const performAPICall = async (url, setUserData, setIsAdmin) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    });

    if (response.status === 401) {
      // window.location.href = "/login";
    }
    const data = await response.json();
    if (data.status === "failed") {
      setUserData(null);
    } else if (data.message && data.status === "success") {
      setUserData(data.message);
      /* Update isAdmin based on user_type */
      const userIsAdmin = data.message && data.message.user_type === "admin";
      setIsAdmin(userIsAdmin);
    }
  } catch (error) {
    console.error({ error });
  }
};

const verifyLogin = async ({ userData, setUserData, setIsAdmin }) => {
  if (!userData) {
    const url = `${apiUrl}?identifier=verifyLogin&noUserData=true`;
    performAPICall(url, setUserData, setIsAdmin);
  } else {
    setUserData(null);
  }
};

export { Login, verifyLogin };
