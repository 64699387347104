import React, { useState, useRef, useEffect } from "react";
import { apiUrl } from "./apiConfig";
import "../scss/_admin-page.scss";
import axios from "axios";
import showToast from "./ToastUtils";

const AddClient = ({
  showAddClientPopup,
  setShowAddClientPopup,
  fetchClientData,
}) => {
  const popupRef = useRef(null);
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    /* Handle outside click to close the popup */
    const handleOutsideClick = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setShowAddClientPopup(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const togglePopup = (e) => {
    setShowAddClientPopup(false);
  };
  const handleClick = () => {
    handleAddClient(clientName);
  };
  const handleAddClient = async (clientName) => {
    try {
      const url = `${apiUrl}?identifier=add-client&client_name=${encodeURIComponent(
        clientName
      )}`;

      const response = await axios.post(url, null, {
        withCredentials: true,
        headers: {},
      });

      if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "success") {
          showToast("success", "Client added successfully");
          fetchClientData();
        } else {
          showToast("error", response.data.message);
        }
      } else {
        throw new Error("Failed to add client");
      }
    } catch (error) {
      showToast("error", "Error adding client");
    }
  };

  return (
    <>
      {/* {popup && ( */}
      <div className={`overlay   ${showAddClientPopup ? "active" : ""}`}>
        <div className="close-btn-wrapper">
          <button className="popup-close" onClick={togglePopup}>
            <img src="./image/close-small.svg" alt="Close" />
          </button>
        </div>
        <div className="content" ref={popupRef}>
          <h2 className="title">Add Client</h2>
          <div className="add-client">
            <div className="client-wrap">
              <label>Client</label>
              <input
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder="Enter Client Name"
              />
              <button onClick={handleClick}>Add Client</button>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default AddClient;
