import React, { useState, useEffect, useRef } from "react";
import { apiUrl } from "./apiConfig";

const Header = ({ userData, setUserData }) => {
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const isAdmin = userData && userData.user_type === "admin";
  // const isAdmin = true;
  const dropdownRef = useRef(null);

  /* Dropdown Close */
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest("a.admin-page-link")
    ) {
      setShowUserDropdown(false);
    }
  };

  /* Dropdown Open */
  const handleUserDropdown = (event) => {
    event.preventDefault();
    setShowUserDropdown(!showUserDropdown);
  };

  /* Client Side Cookie Clearing */
  const clearCookie = (cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  /* Logout */
  const handleLogout = () => {
    clearCookie("mut_user");
    fetch(`${apiUrl}?identifier=logout`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401) {
          window.location.href = "/";
        }
        return response.json();
      })
      .then(() => {
        window.location.href = "#/login";
      })
      .catch((error) => {
        console.error({ error });
      });
    setUserData(null);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setShowUserDropdown]);

  return (
    <header className="main-header">
      <div className="wrap">
        {/* Main Logo */}
        <a href="#/home">
          <img src="./image/Logo.svg" alt="Matsio" />
        </a>
        {/* Dropdown */}
        <div className="right">
          <div className="dropdown-wrap" ref={dropdownRef}>
            {/* User profile */}
            {userData && (
              <a href="" className="user-details" onClick={handleUserDropdown}>
                {userData.profile_image ? (
                  <img src={userData.profile_image} alt={userData.name} />
                ) : (
                  <img src="./image/default.png" alt={userData.name} />
                )}
                <p>{userData.name}</p>
              </a>
            )}

            {/* Handle logout */}
            {showUserDropdown && (
              <div className="user-dropdown">
                <a href="#" onClick={handleLogout}>
                  Logout
                </a>
                <a href="#/pipeline">Pipeline</a>
                {isAdmin && (
                  <a href="#/admin" className="admin-page-link">
                    Admin Page
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
