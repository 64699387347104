import React from "react";
import "../scss/_custom-popup.scss";

const CustomPopup = ({ message, onOk, onCancel, type }) => {
  const renderButtons = () => {
    if (type === "confirmation") {
      return (
        <div className="button-container">
          <button onClick={onCancel} className="btn cancel">
            Cancel
          </button>
          <button onClick={onOk} className="btn ok">
            OK
          </button>
        </div>
      );
    } else {
      return (
        <div className="button-container">
          <button onClick={onOk} className="btn ok">
            OK
          </button>
        </div>
      );
    }
  };
  return (
    <div className="custom-popup-overlay">
      <div className="content">
        <p>{message}</p>
        {renderButtons()}
      </div>
    </div>
  );
};

export default CustomPopup;
