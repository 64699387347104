import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "./apiConfig";
import "../scss/_user-wise-report.scss";
import DateRangePicker from "./DateRangePicker";
import { format } from "date-fns";

const UserReport = () => {
  /* States */
  const today = new Date();
  const formattedToday = format(today, "yyyy-MM-dd");

  const [allUsers, setAllUsers] = useState([]);
  const [allUsersReport, setAllUsersReport] = useState({});
  const [selectedUserId, setSelectedUserId] = useState("all");
  const [startDate, setStartDate] = useState(formattedToday);
  const [endDate, setEndDate] = useState(formattedToday);
  const [selectedStartDate, setSelectedStartDate] = useState(formattedToday);
  const [selectedEndDate, setSelectedEndDate] = useState(formattedToday);
  const [selectedUserReport, setSelectedUserReport] = useState({});
  const [detailsVisible, setDetailsVisible] = useState(false); // Single toggle for all users

  /* API call to fetch all users */
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}?identifier=get-users`, {
        withCredentials: true,
      });
      const allUsersData = response.data.data;
      setAllUsers(allUsersData);
    } catch (error) {
      console.error("Error fetching all users:", error);
    }
  };

  /* API call to fetch report data for all users */
  const fetchAllUsersReport = async (startDateString, endDateString) => {
    try {
      const response = await axios.get(
        `${apiUrl}?identifier=report-users&start=${startDateString}&end=${endDateString}`,
        { withCredentials: true }
      );
      const allUsersReportData = response.data.data;
      setAllUsersReport(allUsersReportData);
      setSelectedUserReport(
        selectedUserId === "all"
          ? allUsersReportData
          : allUsersReportData[selectedUserId] || {}
      );
    } catch (error) {
      console.error("Error fetching all users' report:", error);
    }
  };

  /* Fetch all users and their reports on initial mount */
  useEffect(() => {
    fetchAllUsers();
    fetchAllUsersReport(formattedToday, formattedToday);
  }, []);

  /* Handle user dropdown change */
  const handleUserChange = (event) => {
    const userId = event.target.value;
    setSelectedUserId(userId);
    setSelectedUserReport(
      userId === "all" ? allUsersReport : allUsersReport[userId] || {}
    );
  };

  /* Handle Go button click */
  const handleGoClick = () => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    fetchAllUsersReport(selectedStartDate, selectedEndDate);
  };

  /* Toggle details visibility for all users */
  const toggleDetailsVisibility = () => {
    setDetailsVisible(!detailsVisible);
  };

  /* Convert minutes to hours */
  const renderTotalTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    if (hours === 0) {
      return `${mins} mins`;
    } else if (mins === 0) {
      return `${hours} hours`;
    } else {
      return `${hours} hours ${mins} mins`;
    }
  };

  /* Calculate total time for each user */
  const calculateTotalTime = (userReport) => {
    let totalTime = 0;
    Object.values(userReport).forEach((client) => {
      if (client.client_id) {
        Object.values(client).forEach((project) => {
          if (project.project_id) {
            totalTime += parseInt(project.time, 10) || 0;
          }
        });
      }
    });
    return totalTime;
  };

  return (
    <div className="user-report-container">
      <div className="date-picker-sec">
        <DateRangePicker
          onDateChange={(start, end) => {
            setSelectedStartDate(start);
            setSelectedEndDate(end);
          }}
        />
        <button onClick={handleGoClick} className="go-btn">
          Go
        </button>
      </div>
      <div className="user-select-outer">
        <div className="user-select">
          <select onChange={handleUserChange} value={selectedUserId}>
            <option value="all">All</option>
            {allUsers.map((user) => (
              <option key={user.user_id} value={user.user_id}>
                {user.user_name}
              </option>
            ))}
          </select>
        </div>
        {selectedUserId === "all" && (
          <button onClick={toggleDetailsVisibility} className="details-btn">
            {detailsVisible ? "Hide Details" : "Show Details"}
          </button>
        )}
      </div>
      <div className="user-details-wrapper">
        {selectedUserId === "all" ? (
          <ul className="user-list">
            {Object.keys(allUsersReport).map((userId) => {
              const userReport = allUsersReport[userId];
              const user = allUsers.find(
                (user) => user.user_id === parseInt(userId, 10)
              );
              if (user) {
                return (
                  <li key={user.user_id} className="user-item">
                    <div className="user-details">
                      <h3 className="user-name">{user.user_name}</h3>
                      <span className="time">
                        {renderTotalTime(calculateTotalTime(userReport))}
                      </span>
                    </div>
                    {detailsVisible && (
                      <ul className="client-list">
                        {Object.values(userReport).map((client) => {
                          if (client.client_id) {
                            return (
                              <li
                                key={client.client_id}
                                className="client-item"
                              >
                                <h4 className="client-name">
                                  {client.client_name}
                                </h4>
                                <ul className="project-list">
                                  {Object.values(client).map((project) => {
                                    if (project.project_id) {
                                      return (
                                        <li
                                          key={project.project_id}
                                          className="project-item"
                                        >
                                          <span className="project-name">
                                            {project.project_name}
                                          </span>
                                          <span className="time">
                                            {renderTotalTime(
                                              parseInt(project.time, 10) || 0
                                            )}
                                          </span>
                                        </li>
                                      );
                                    }
                                    return null;
                                  })}
                                </ul>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    )}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        ) : (
          <div className="individual-user-details">
            <div className="user-details-top">
              <h3 className="user-name">
                {
                  allUsers.find(
                    (user) => user.user_id === parseInt(selectedUserId, 10)
                  )?.user_name
                }
              </h3>
              <span className="total-time">
                Total Time:{" "}
                {renderTotalTime(calculateTotalTime(selectedUserReport))}
              </span>
            </div>
            <ul className="client-list">
              {Object.values(selectedUserReport).length > 0 ? (
                Object.values(selectedUserReport).map((client) => {
                  if (client.client_id) {
                    return (
                      <li key={client.client_id} className="client-item">
                        <h4 className="client-name">{client.client_name}</h4>
                        <ul className="project-list">
                          {Object.values(client).map((project) => {
                            if (project.project_id) {
                              return (
                                <li
                                  key={project.project_id}
                                  className="project-item"
                                >
                                  <span className="project-name">
                                    {project.project_name}
                                  </span>
                                  <span className="time">
                                    {renderTotalTime(
                                      parseInt(project.time, 10) || 0
                                    )}
                                  </span>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </ul>
                      </li>
                    );
                  }
                  return null;
                })
              ) : (
                <li>No data available</li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserReport;
