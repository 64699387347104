import React from 'react';
import moment from 'moment';

function DateComponent({ date, time }) {
  const currentDate = moment();
  const inputDate = moment(date, 'YYYY-MM-DD HH:mm:ss');

  const increasedDate = inputDate.add(5, 'hours').add(30, 'minutes');

  const isToday = increasedDate.isSame(currentDate, 'day');
  const isYesterday = increasedDate.isSame(currentDate.clone().subtract(1, 'day'), 'day');
  const isTomorrow = increasedDate.isSame(currentDate.clone().add(1, 'day'), 'day');

  let formattedDate;
  if (time) {
    const inputdate = new Date(increasedDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now - inputdate) / 1000);
    if (diffInSeconds < 60) {
      formattedDate = `${diffInSeconds} secs ago`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      formattedDate = `${diffInMinutes} mins ago`;
    } else {
      formattedDate = moment(inputDate).format('MMM Do [at] h:mm:ss A');
    }
  } else {
    if (isToday) {
      formattedDate = 'Today';
    } else if (isYesterday) {
      formattedDate = 'Yesterday';
    } else if (isTomorrow) {
      formattedDate = 'Tomorrow';
    } else {
      formattedDate = increasedDate.format('MMMM DD');
    }
  }
  return <div className="date-format">{formattedDate}</div>;
}

export default DateComponent;
