import React, { useState, useRef, useEffect } from "react";
import { apiUrl } from "./apiConfig";
import "../scss/_admin-page.scss";
import axios from "axios";
import showToast from "./ToastUtils";

const AddTask = ({ showAddTypePopup, setShowAddTypePopup, fetchTypeData }) => {
  const popupRef = useRef(null);

  const togglePopup = (e) => {
    setShowAddTypePopup(false);
  };
  const [typeName, setTypeName] = useState("");
  const [rate, setRate] = useState("");

  const handleChangeTypeName = (e) => {
    setTypeName(e.target.value);
  };

  const handleChangeRate = (e) => {
    setRate(e.target.value);
  };

  const handleTypeClick = () => {
    handleAddTypeOfWork(typeName, rate);
  };

  useEffect(() => {
    /* Handle outside click to close the popup */
    const handleOutsideClick = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setShowAddTypePopup(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleAddTypeOfWork = async (typeName) => {
    const params = new URLSearchParams();
    params.append("identifier", "add-type");
    params.append("type_name", typeName);
    params.append("rate", rate);

    try {
      const url = `${apiUrl}?${params.toString()}`;

      const response = await axios.post(url, null, {
        withCredentials: true,
        headers: {},
      });

      if (response.status >= 200 && response.status < 300) {
        const responseData = response.data;
        if (responseData.status === "success") {
          showToast("success", "Type added successfully");
          fetchTypeData();
        } else {
          showToast("error", responseData.message);
        }
      } else {
        throw new Error("Failed to add type");
      }
    } catch (error) {
      showToast("error", "Error adding type");
    }
  };

  return (
    <>
      <div className={`overlay   ${showAddTypePopup ? "active" : ""}`}>
        <div className="close-btn-wrapper">
          <button className="popup-close" onClick={togglePopup}>
            <img src="./image/close-small.svg" alt="Close" />
          </button>
        </div>
        <div className="content" ref={popupRef}>
          <h2 className="title">Add Type</h2>
          <div className="add-task">
            <div className="type-wrap">
              <label htmlFor="task-name">Type</label>
              <input
                id="task-name"
                type="text"
                value={typeName}
                onChange={handleChangeTypeName}
                placeholder="Enter Type of Work (Task)"
              />
              <label htmlFor="rate">Rate</label>
              <input
                id="rate"
                type="text"
                value={rate}
                onChange={handleChangeRate}
                placeholder="Enter Rate"
              />
              <button onClick={handleTypeClick}>Add Task</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTask;
