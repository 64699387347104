import React, { useState } from "react";
import CustomPopup from "./CustomPopup";

const CustomPopupUtils = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  const showPopup = (message, type, onOk, onCancel) => {
    setPopupMessage(message);
    setPopupType(type);
    setPopupVisible(true);
    /* Set the callback functions */
    setConfirmationResult({
      onOk: () => {
        hidePopup();
        onOk && onOk(true);
      },
      onCancel: () => {
        hidePopup();
        onCancel && onCancel(false);
      },
    });
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const handleOk = () => {
    confirmationResult && confirmationResult.onOk && confirmationResult.onOk();
  };

  const handleCancel = () => {
    confirmationResult &&
      confirmationResult.onCancel &&
      confirmationResult.onCancel();
  };

  const renderPopup = () => {
    return (
      <CustomPopup
        message={popupMessage}
        onOk={handleOk}
        onCancel={handleCancel}
        type={popupType}
      />
    );
  };

  return {
    showPopup,
    hidePopup,
    renderPopup,
  };
};

export default CustomPopupUtils;
