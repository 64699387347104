import React, { useState } from "react";
import "../scss/_reports.scss";
import ProjectReport from "./ProjectReport";
import UserReport from "./UserReport";

const Reports = () => {
  /* States */
  const [activeTab, setActiveTab] = useState("project");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="report-page-container">
      <div className="tab-section">
        <button
          className={`tab ${activeTab === "project" ? "active" : ""}`}
          onClick={() => handleTabClick("project")}
        >
          Project
        </button>
        <button
          className={`tab ${activeTab === "user" ? "active" : ""}`}
          onClick={() => handleTabClick("user")}
        >
          Users
        </button>
      </div>
      <div className="content-section">
        <div className="wrap">
          {activeTab === "project" && <ProjectReport />}
          {activeTab === "user" && <UserReport />}
        </div>
      </div>
    </div>
  );
};

export default Reports;
