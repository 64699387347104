import React, { useState } from "react";
import "../scss/_utility-list.scss";
import { apiUrl } from "./apiConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomPopup from "./CustomPopup";
import showToast from "./ToastUtils";

const UtilityList = ({
  logList,
  handleAddTime,
  handleUpdateTime,
  projectList,
  newLogList,
  setnewLogList,
  setLogList,
  weeks,
  setWeeks,
  generateWeekDates,
  addLog,
  startDateString,
  setStartDateString,
  endDateString,
  setEndDateString,
  fetchLogData,
}) => {
  const navigate = useNavigate();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [deletingRows, setDeletingRows] = useState([]);

  const showPopup = (message, type, onOk, onCancel) => {
    setPopupMessage(message);
    setPopupType(type);
    setPopupVisible(true);
    // Set the callback functions
    setConfirmationResult({
      onOk: () => {
        hidePopup();
        onOk && onOk(true);
      },
      onCancel: () => {
        hidePopup();
        onCancel && onCancel(false);
      },
    });
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const handleOk = () => {
    confirmationResult && confirmationResult.onOk && confirmationResult.onOk();
  };

  const handleCancel = () => {
    confirmationResult &&
      confirmationResult.onCancel &&
      confirmationResult.onCancel();
  };

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const changeToNextWeek = (index) => {
    setWeeks((prevWeeks) => {
      const nextWeekStartDate = new Date(prevWeeks[index].startDate);
      nextWeekStartDate.setDate(prevWeeks[index].startDate.getDate() + 7);
      const nextWeekEndDate = new Date(nextWeekStartDate);
      nextWeekEndDate.setDate(nextWeekStartDate.getDate() + 6);

      /* Call fetchLogData with new date values directly */
      fetchLogData(
        nextWeekStartDate.toISOString().split("T")[0],
        nextWeekEndDate.toISOString().split("T")[0]
      );
      const newWeeks = [...prevWeeks];
      newWeeks[index] = {
        startDate: nextWeekStartDate,
        endDate: nextWeekEndDate,
        dates: generateWeekDates(nextWeekStartDate),
        inputValues: Array(7).fill(""),
      };
      /*  Update startDateString and endDateString */
      const startDate = nextWeekStartDate.toISOString().split("T")[0];
      const endDate = nextWeekEndDate.toISOString().split("T")[0];
      setStartDateString(startDate);
      setEndDateString(endDate);
      return newWeeks;
    });
  };

  const changeToPreviousWeek = (index) => {
    setWeeks((prevWeeks) => {
      const previousWeekStartDate = new Date(prevWeeks[index].startDate);
      previousWeekStartDate.setDate(prevWeeks[index].startDate.getDate() - 7);
      const previousWeekEndDate = new Date(previousWeekStartDate);
      previousWeekEndDate.setDate(previousWeekStartDate.getDate() + 6);

      /* Call fetchLogData with new date values directly */
      fetchLogData(
        previousWeekStartDate.toISOString().split("T")[0],
        previousWeekEndDate.toISOString().split("T")[0]
      );
      const newWeeks = [...prevWeeks];
      newWeeks[index] = {
        startDate: previousWeekStartDate,
        endDate: previousWeekEndDate,
        dates: generateWeekDates(previousWeekStartDate),
        inputValues: Array(7).fill(""),
      };
      /*  Update startDateString and endDateString */
      const startDate = previousWeekStartDate.toISOString().split("T")[0];
      const endDate = previousWeekEndDate.toISOString().split("T")[0];
      setStartDateString(startDate);
      setEndDateString(endDate);
      return newWeeks;
    });
  };

  const addNewLog = (log, date, inputValue) => {
    const formData = {
      project_id: log.project_id,
      client_id: log.client_id,
      client_name: log.client_name,
      date: date,
      log_id:
        new Date().toISOString() /* generate a new log_id for each new log */,
      project_name: log.project_name,
      rate: log.rate,
      type_id: log.type_id,
      type_name: log.type_name,
      time: inputValue,
    };
    addLog(formData);
  };

  /* Update Log time */
  const updateLogTime = (log, date, newValue, logID) => {
    const projectId = log.project_id;
    const clientId = log.client_id;
    const typeId = log.type_id;
    const passedLogID = logID;
    const passedDate = date.toLocaleDateString();

    const existsInLogList = logList.some(
      (existingLog) =>
        existingLog.project_id === projectId &&
        existingLog.client_id === clientId &&
        existingLog.type_id === typeId &&
        existingLog.logs.some((logEntry) => logEntry.log_id === passedLogID)
    );

    const existsInNewLogList = newLogList.some(
      (existingLog) =>
        existingLog.project_id === projectId &&
        existingLog.client_id === clientId &&
        existingLog.type_id === typeId &&
        existingLog.logs.some((logEntry) => {
          const logEntryDate = new Date(logEntry.date).toLocaleDateString();
          return logEntryDate === passedDate;
        })
    );

    if (existsInNewLogList) {
      /* Log exists in newLogList, update its time */
      const existingLogIndex = newLogList.findIndex(
        (existingLog) =>
          existingLog.project_id === projectId &&
          existingLog.client_id === clientId &&
          existingLog.type_id === typeId &&
          existingLog.logs.some((logEntry) => logEntry.log_id === passedLogID)
      );
      const existingLog = newLogList[existingLogIndex];
      const updatedLogs = existingLog.logs.map((logEntry) =>
        logEntry.log_id === passedLogID
          ? { ...logEntry, time: newValue }
          : logEntry
      );
      const updatedLog = { ...existingLog, logs: updatedLogs };
      const updatedNewLogList = [...newLogList];
      updatedNewLogList[existingLogIndex] =
        updatedLog; /* Update the specific log entry */
      setnewLogList(updatedNewLogList);

      /* Update the corresponding time in logList */
      const updatedLogList = logList.map((existingLog) =>
        existingLog.project_id === projectId &&
        existingLog.client_id === clientId &&
        existingLog.type_id === typeId &&
        existingLog.logs.some((logEntry) => logEntry.log_id === passedLogID)
          ? {
              ...existingLog,
              logs: existingLog.logs.map((logEntry) =>
                logEntry.log_id === passedLogID
                  ? { ...logEntry, time: newValue }
                  : logEntry
              ),
            }
          : existingLog
      );
      setLogList(updatedLogList);
    } else if (existsInLogList) {
      /* Log exists in logList but not in newLogList, add it to newLogList */
      const logToAdd = logList.find(
        (existingLog) =>
          existingLog.project_id === projectId &&
          existingLog.client_id === clientId &&
          existingLog.type_id === typeId &&
          existingLog.logs.some((logEntry) => logEntry.log_id === passedLogID)
      );
      const logToAddWithNewTime = {
        ...logToAdd,
        logs: logToAdd.logs.map((logEntry) =>
          logEntry.log_id === passedLogID
            ? { ...logEntry, time: newValue }
            : logEntry
        ),
      };
      /* Add the log entry to newLogList */
      setnewLogList([...newLogList, logToAddWithNewTime]);

      /* Update the corresponding time in logList */
      const updatedLogList = logList.map((existingLog) =>
        existingLog.project_id === projectId &&
        existingLog.client_id === clientId &&
        existingLog.type_id === typeId &&
        existingLog.logs.some((logEntry) => logEntry.log_id === passedLogID)
          ? {
              ...existingLog,
              logs: existingLog.logs.map((logEntry) =>
                logEntry.log_id === passedLogID
                  ? { ...logEntry, time: newValue }
                  : logEntry
              ),
            }
          : existingLog
      );
      setLogList(updatedLogList);
    } else {
    }
  };

  const renderWeekDays = (logList) => {
    return weeks.map((week, weekIndex) => (
      <div key={weekIndex} className="outer-wrapper">
        <div className="top">
          <h2>Utilization Tracker</h2>
          <div className="right">
            <button
              className="btn"
              onClick={() => changeToPreviousWeek(weekIndex)}
            >
              <img src="./image/previous.svg" alt="" />
            </button>
            <span>
              <span>
                {" "}
                {
                  month[week.startDate.getMonth()]
                } {week.startDate.getDate()} -{" "}
              </span>
              <span>
                {week.endDate.getDate()} {", "} {week.endDate.getFullYear()}
              </span>
            </span>
            <button
              className="btn next"
              onClick={() => changeToNextWeek(weekIndex)}
            >
              <img src="./image/previous.svg" alt="" />
            </button>
          </div>
        </div>
        <div className="date-row-wrapper">
          <div className="left">Project Details</div>
          <div className="right">
            {week.dates.map((date, dayIndex) => {
              const isToday = date.toDateString() === new Date().toDateString();
              const dateClassName = isToday ? "today-date" : "";

              return (
                <div key={dayIndex} className={`date ${dateClassName}`}>
                  <div className="day">{day[date.getDay()]}</div>
                  <span>
                    {date.getDate()} {""}
                    {month[date.getMonth()]}
                  </span>
                  {/* <div className="total-time">
                    {calculateTotalTimeForDay(date)}
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    ));
  };

  const renderTotalTime = (logList) => {
    return weeks.map((week, weekIndex) => (
      <div key={weekIndex} className="total-time-outer-wrapper">
        <div className="content">
          {week.dates.map((date, dayIndex) => {
            return (
              <div key={dayIndex} className={`total-time-per-day`}>
                {calculateTotalTimeForDay(date)}
              </div>
            );
          })}
        </div>
      </div>
    ));
  };

  const getLogIdsForOneWeek = (log) => {
    /* get log IDs for the current week */
    const currentWeekLogIds = log.logs.map((logEntry) => logEntry.log_id);
    return currentWeekLogIds;
  };

  const handleDeleteRow = async (log, projectId, logIdList) => {
    const logId = logIdList[0];
    try {
      const response = await axios.get(
        `${apiUrl}?identifier=delete-log&id=${logId}`,
        { withCredentials: true }
      );

      if (response.status === 401) {
        navigate("/login");
      }

      const projectId = log.project_id;
      const projectIndex = logList.findIndex(
        (proj) => proj.project_id === projectId
      );

      /*  Update the logList if deletion is successful */
      const updatedLogs = logList[projectIndex].logs.filter(
        (log) => log.log_id !== logId
      );
      const updatedLogList = [...logList];
      updatedLogList[projectIndex] = {
        ...updatedLogList[projectIndex],
        logs: updatedLogs,
      };
      setLogList(updatedLogList);
      showToast("success", "Log deleted successfully");
    } catch (error) {
      showToast("error", "Error deleting log entry");
    }
    setDeletingRows((prevDeletingRows) => [...prevDeletingRows, projectId]);
    setTimeout(() => {
      /* Filter out the project with the given projectId from logList */
      const updatedLogList = logList.filter(
        (project) => project.project_id !== projectId
      );

      /* Update the state with the modified logList */
      setLogList(updatedLogList);
      setDeletingRows((prevDeletingRows) =>
        prevDeletingRows.filter((rowId) => rowId !== projectId)
      );
    }, 500);
    showToast("success", "One row deleted");
  };

  const renderInputs = (logList, openModal, openUpdateModal) => {
    /* Filter logListArray to exclude logs with empty logs array */

    const logListArray = Object.values(logList).filter(
      (log) => log.logs.length > 0
    );

    return (
      <>
        <div className="cell-outer-wrapper">
          {logListArray.map((log) => {
            /* const hasOneLog = log.logs.length <= 1; */

            const hasOneLog =
              (log.logs.some(
                (logEntry) =>
                  logEntry.time !== undefined &&
                  logEntry.time !== null &&
                  logEntry.time === ""
              ) &&
                log.logs.length >= 1) ||
              log.logs.length < 2;

            /* Calculate weekly total */
            const calculateTotalMinutes = (timeString) => {
              const [hours, minutes] = timeString.split(":").map(Number);
              return hours * 60 + minutes;
            };

            const calculateTotalTime = (totalMinutes) => {
              if (isNaN(totalMinutes)) {
                return "0:00"; /* Return '0:00' if the input is NaN or empty */
              }
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
              return `${hours}:${String(minutes).padStart(2, "0")}`;
            };

            const weeklyTotalTimeInMinutes = log.logs.reduce((total, entry) => {
              if (entry.time.trim() === "") {
                return total; /* Ignore empty or whitespace-only entries */
              }
              const timeInMinutes = calculateTotalMinutes(entry.time);
              return total + timeInMinutes;
            }, 0);

            const weeklyTotalTime = calculateTotalTime(
              weeklyTotalTimeInMinutes
            );

            const isAnyDateEditable = logList.some((log) =>
              log.logs.some((logEntry) => {
                const currentDate = new Date();
                const yesterday = new Date(currentDate);
                yesterday.setDate(currentDate.getDate() - 1);

                const entryDate = new Date(logEntry.date);

                return (
                  entryDate >= yesterday ||
                  entryDate.toDateString() === yesterday.toDateString()
                );
              })
            );

            return (
              <div
                key={log.project_id}
                className={`input-wrapper row-to-delete ${
                  deletingRows.includes(log.project_id) ? "leaving" : ""
                } ${hasOneLog && isAnyDateEditable ? "deletable" : ""}`}
              >
                <div className="each-item">
                  <div className="log-details">
                    <div className="left">
                      <div className="task-details">
                        <div className="top-row">
                          <p className="project">{log.client_name}</p>
                        </div>
                        <div className="bottom-row">
                          {log.project_name} {" / "}{" "}
                          <span className="task-name">{log.type_name}</span>
                        </div>
                      </div>
                    </div>

                    {weeks.map((week, weekIndex) => (
                      <div key={weekIndex} className="week-container">
                        {week.dates.map((date, dayIndex) => {
                          const logEntryForDate = log.logs.find((logEntry) => {
                            const entryDate = new Date(logEntry.date);
                            return (
                              entryDate.getDate() === date.getDate() &&
                              entryDate.getMonth() === date.getMonth() &&
                              entryDate.getFullYear() === date.getFullYear()
                            );
                          });

                          return (
                            <InputBox
                              key={dayIndex}
                              logEntryForDate={logEntryForDate}
                              logId={
                                logEntryForDate ? logEntryForDate.log_id : null
                              }
                              log={log}
                              date={date}
                              addNewLog={addNewLog}
                            />
                          );
                        })}
                      </div>
                    ))}
                  </div>
                  <div className="week-total">{weeklyTotalTime}</div>
                  {isAnyDateEditable && (
                    <button
                      onClick={() =>
                        handleDeleteRow(
                          log,
                          log.project_id,
                          getLogIdsForOneWeek(log)
                        )
                      }
                      disabled={!hasOneLog}
                      className="delete-row-btn"
                    >
                      <img src="./image/close-small.svg" alt="Delete" />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const InputBox = ({ logEntryForDate, logId, log, date, addNewLog }) => {
    const [typedValue, setTypedValue] = useState(
      logEntryForDate ? logEntryForDate.time : ""
    );
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const onDelete = async (log, logId) => {
      const confirmed = await new Promise((resolve) => {
        showPopup(
          "Are you sure you want to delete this log entry?",
          "confirmation",
          (result) => {
            resolve(result);
          }
        );
      });

      if (!confirmed) {
        return;
      }

      const projectId = log.project_id;
      const projectIndex = logList.findIndex(
        (proj) => proj.project_id === projectId
      );

      const projectIndexInNewLogList = newLogList.findIndex(
        (proj) => proj.project_id === projectId
      );

      const logPresentInLogList = logList[projectIndex]?.logs.find(
        (logEntry) => logEntry.log_id === logId
      );
      const logPresentInNewList = newLogList[
        projectIndexInNewLogList
      ]?.logs.find((logEntry) => logEntry.log_id === logId);

      if (logPresentInLogList && logPresentInNewList) {
        /* Log is present in both lists */
        const updatedLogs = logList[projectIndex].logs.filter(
          (log) => log.log_id !== logId
        );
        const updatedLogList = [...logList];
        updatedLogList[projectIndex] = {
          ...updatedLogList[projectIndex],
          logs: updatedLogs,
        };
        setLogList(updatedLogList);

        /* Remove the entire row from newLogList */
        const updatedNewLogList = [...newLogList];
        updatedNewLogList.splice(projectIndexInNewLogList, 1);
        setnewLogList(updatedNewLogList);

        showToast("success", "Log deleted successfully");
      } else if (logPresentInLogList && !logPresentInNewList) {
        try {
          const response = await axios.get(
            `${apiUrl}?identifier=delete-log&id=${logId}`,
            { withCredentials: true }
          );

          if (response.status === 401) {
            navigate("/login");
          }

          /* Update the logList if deletion is successful */
          const updatedLogs = logList[projectIndex].logs.filter(
            (log) => log.log_id !== logId
          );
          const updatedLogList = [...logList];
          updatedLogList[projectIndex] = {
            ...updatedLogList[projectIndex],
            logs: updatedLogs,
          };
          setLogList(updatedLogList);
          showToast("success", "Log deleted successfully");
        } catch (error) {
          console.error("Error deleting log entry:", error.message);
          showToast("error", "Error deleting log entry");
        }
      }
    };

    /* Check if the date is today, yesterday, or in the future */
    const isEditableDate =
      date >= yesterday || date.toDateString() === yesterday.toDateString();

    const handleChange = (e) => {
      const newValue = e.target.value;
      setTypedValue(newValue);
    };

    const displayMessage = (message) => {
      showPopup(message);
    };

    const formatTimeInput = (value) => {
      /* Remove any non-digit characters */
      const cleanedValue = value.replace(/\D/g, "");

      /* Check if the value contains a colon (":") */
      const isTimeFormat = value.includes(":");

      if (isTimeFormat) {
        /* Extract hours and minutes */
        const [hoursPart, minutesPart] = value.split(":");
        const hours = parseInt(hoursPart.padStart(2, "0"));
        const minutes = parseInt(minutesPart.padEnd(2, "0"));

        /* Calculate total time in minutes */
        const totalTime = hours * 60 + minutes;

        /* Check if total time exceeds 24 hours */
        if (totalTime > 1440) {
          displayMessage("Time cannot exceed 24 hours.");
          return "";
        }

        /* Calculate formatted hours and minutes with leading zeros */
        const formattedTotalHours = Math.floor(totalTime / 60);
        const formattedTotalMinutes = totalTime % 60;
        const formattedHours = String(formattedTotalHours).padStart(2, "0");
        const formattedMinutes = String(formattedTotalMinutes).padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}`;
      } else if (cleanedValue !== "") {
        /* If no colon (":") and value is not empty, interpret as hours and minutes */
        let hours = 0;
        let minutes = 0;

        /* Check if the value contains a dot (".") */
        if (value.includes(".")) {
          const [hoursPart, minutesPart] = value.split(".");
          hours = parseInt(hoursPart.padStart(2, "0"));
          if (minutesPart === "5") {
            minutes = 30;
          } else {
            minutes = parseInt(minutesPart.padEnd(2, "0"));
          }
        } else {
          /* If no dot, interpret as hours only */
          hours = parseInt(cleanedValue.padStart(2, "0"));
        }

        /* Check if hours exceed 24 */
        if (hours > 24) {
          showPopup("Time cannot exceed 24 hours.");
          return "";
        }

        /* Return in hh:mm format */
        return `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
      } else {
        return "";
      }
    };
    const handleKeyDown = (e) => {
      if (e.key === "Tab") {
        /* Move focus to the next input field */
        e.preventDefault();
        const inputs = document.querySelectorAll(".input-div");
        const index = Array.from(inputs).indexOf(e.target);
        const nextIndex = index + 1;
        if (nextIndex < inputs.length) {
          const nextInput = inputs[nextIndex];

          /* Check if the nextInput is not null or undefined */
          if (nextInput) {
            /* Focus on the next input element */
            nextInput.focus();

            /* Optionally, add a CSS class to make it visually active */
            nextInput.classList.add("active");
          }
        }
      }
    };

    return (
      <div className="input-outer">
        {typedValue && isEditableDate && (
          <button
            className="delete-button"
            onClick={() => onDelete(log, logId)}
          ></button>
        )}
        <input
          className={`input-div ${!isEditableDate ? "read-only" : ""}`}
          value={typedValue}
          readOnly={!isEditableDate} /* Set read-only for future dates */
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={(e) => {
            const newValue = e.target.value;
            const formattedValue = formatTimeInput(newValue);

            if (!logEntryForDate && e.target.value.trim() !== "") {
              /* Check if input value is not empty */
              addNewLog(log, date, formattedValue);
            } else {
              /* Check if there is a value in the input */
              setTimeout(() => {
                updateLogTime(log, date, formattedValue, logId);
              }, 0);
              /* updateLogTime(log, date, formattedValue, logId); */
            }
          }}
        />
      </div>
    );
  };

  const calculateTotalTimeForDay = (dateToCheck) => {
    const totalMinutes = logList.reduce((totalTime, log) => {
      log.logs.forEach((logEntry) => {
        const logDate = new Date(logEntry.date);
        const isSameDate =
          logDate.toDateString() === new Date(dateToCheck).toDateString();

        if (isSameDate && logEntry.time) {
          const [hours, minutes] = logEntry.time.split(":").map(parseFloat);
          if (!isNaN(hours) && !isNaN(minutes)) {
            totalTime +=
              hours * 60 +
              minutes; /*  Convert hours to minutes and add to total */
          }
        }
      });
      return totalTime;
    }, 0);

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}:${String(minutes).padStart(2, "0")}`; /* Format as h:mm */
  };

  return (
    <>
      <div className="main-wrapper">
        {renderWeekDays(logList)}
        {logList.length === 0 ? (
          <p className="no-data">No Data Available</p>
        ) : (
          <> {renderInputs(logList)}</>
        )}
        {isPopupVisible && (
          <CustomPopup
            message={popupMessage}
            onOk={handleOk}
            onCancel={handleCancel}
            type={popupType}
          />
        )}
      </div>
      {logList.length > 0 && (
        <div className="daily-time-wrapper">{renderTotalTime(logList)}</div>
      )}
    </>
  );
};

export default UtilityList;
