import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import { Login, verifyLogin } from './components/Login';
import Home from './components/Home';
import PipelineHomePage from './components/pipeline/PipelineHomePage';
import AdminPage from './components/AdminPage';

const App = () => {
  /* States */
  const [userData, setUserData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    verifyLogin({ userData, setUserData, setIsAdmin });
  }, []);

  return userData ? (
    <>
      <Header userData={userData} setUserData={setUserData}></Header>
      <Router>
        <Routes>
          <Route path="*" element={<Home userData={userData} />} />
          <Route path="/login" element={<Home userData={userData} />} />
          <Route path="/" element={<Home userData={userData} />} />
          <Route path="/pipeline" element={<PipelineHomePage userData={userData} />} />

          {isAdmin ? (
            <>
              <Route path="/admin/*" element={<AdminPage userData={userData} />} />
            </>
          ) : null}
        </Routes>
      </Router>
    </>
  ) : (
    <Router>
      <Routes>
        <Route path="*" element={<Login setUserData={setUserData} setIsAdmin={setIsAdmin} />} />
      </Routes>
    </Router>
  );
};

export default App;
